import Router from "router";
import "../src/assets/main.css"

function App() {
  return (
    <Router />
  );
}

export default App;
