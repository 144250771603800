import React, { Suspense } from "react";
import { Route, Navigate, BrowserRouter } from "react-router-dom";
import SlideRoutes from 'react-slide-routes';
import { appSettings } from "config/app";
import Layout from "layouts";

const Homepage = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/home"));
const PickUpLockerAssigned = React.lazy(() => import(/* webpackChunkName: "views-locker-assigned" */ "views/pickup/locker/assigned"));
const PickUpPicked = React.lazy(() => import(/* webpackChunkName: "views-picked" */ "views/pickup/picked"));
const SizeSelect = React.lazy(() => import(/* webpackChunkName: "views-deposit/size" */ "views/deposit/size"));
const CompanySelect = React.lazy(() => import(/* webpackChunkName: "views-deposit/company" */ "views/deposit/company"));
const BuildingSelect = React.lazy(() => import(/* webpackChunkName: "views-deposit/building" */ "views/deposit/building"));
const FractionSelect = React.lazy(() => import(/* webpackChunkName: "views-deposit/fraction" */ "views/deposit/fraction"));
const UserSelect = React.lazy(() => import(/* webpackChunkName: "views-deposit/user" */ "views/deposit/user"));
const ConfirmData = React.lazy(() => import(/* webpackChunkName: "views-deposit/confirm" */ "views/deposit/confirm"));
const DepositLockerAssigned = React.lazy(() => import(/* webpackChunkName: "views-deposit/locker" */ "views/deposit/locker"));
const OrderStored = React.lazy(() => import(/* webpackChunkName: "views-deposit/stored" */ "views/deposit/stored"));
const NotFoundPage = React.lazy(() => import(/* webpackChunkName: "views-404" */ "views/404"));

const Router = () => {

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Layout>
          <SlideRoutes animation={appSettings.slider.animation} timing={appSettings.slider.timing} duration={appSettings.slider.duration}>
            <Route index element={<Navigate to="/home" />} />
            <Route exact path="/home" element={<Homepage />} />
            <Route exact path="/locker/assigned" element={<PickUpLockerAssigned />} />
            <Route exact path="/pickup/picked" element={<PickUpPicked />} />
            <Route exact path="/deposit/size" element={<SizeSelect />} />
            <Route exact path="/deposit/company" element={<CompanySelect />} />
            <Route exact path="/deposit/building" element={<BuildingSelect />} />
            <Route exact path="/deposit/fraction" element={<FractionSelect />} />
            <Route exact path="/deposit/user" element={<UserSelect />} />
            <Route exact path="/deposit/confirm" element={<ConfirmData />} />
            <Route exact path="/deposit/locker" element={<DepositLockerAssigned />} />
            <Route exact path="/deposit/stored" element={<OrderStored />} />
            <Route path="*" element={<NotFoundPage />} />
          </SlideRoutes>
        </Layout>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
