import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchSizes, fetchCompanies, fetchBuildings } from 'store/apps/deposit';
import { updateSocketStatus } from 'store/apps/app';
import cn from 'classnames';

export const WebSocket = ({ showIconStatus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { socket } = useSelector((state) => state.app);
  const lockerDeposit = useSelector((state) => state.deposit.locker);
  const lockerDelivery = useSelector((state) => state.delivery.locker);

  const websocketUrl = process.env.REACT_APP_API_WS_URL + '/ws/location-status?access_token=' + process.env.REACT_APP_API_TOKEN;

  const {
    readyState,
  } = useWebSocket(websocketUrl, {
    onOpen: () => changeSocketStatus(true, '::Socket Opened'),
    onClose: () => changeSocketStatus(false, '::Socket Closed'),
    onError: () => changeSocketStatus(false, '::Socket Error'),
    shouldReconnect: () => true,
    reconnectAttempts: 100,
    reconnectInterval: () => 10 * 1000, //60 seconds interval between each attemp
    onMessage: (e) => handleTrigger(e)
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: '::Connecting Socket',
    [ReadyState.OPEN]: '::Socket Opened',
    [ReadyState.CLOSING]: '::Closing Socket',
    [ReadyState.CLOSED]: '::Socket Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const changeSocketStatus = (active, status) => {
    dispatch(updateSocketStatus(active))
    console.log(status)
  }

  const handleTrigger = (e) => {

    if (e.data && e.data.charAt(0) === '{') {
      const parsedMessage = JSON.parse(e.data);

      //console.log(parsedMessage)

      const fetchLocalIP = async () => {
        try {
          const response = await fetch(parsedMessage?.data);
          const result = await response.json();
          console.log(result)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      switch (parsedMessage?.action) {
        case 'refresh_sizes':
          dispatch(fetchSizes(process.env.REACT_APP_LOCATION_ID));
          break;

        case 'refresh_companies':
          dispatch(fetchCompanies());
          break;

        case 'refresh_buildings':
          dispatch(fetchBuildings());
          break;

        case 'refresh_deposit':

          dispatch(fetchSizes(process.env.REACT_APP_LOCATION_ID));
          dispatch(fetchCompanies());
          dispatch(fetchBuildings());
          break;

        case 'order_stored':

          //Check locker ID receive by socket to trigger or not that action
          if (lockerDeposit.selected.id === parsedMessage?.data) {
            navigate('/deposit/stored');
          }

          break;

        case 'order_picked':

          //Check locker ID receive by socket to trigger or not that action
          if (lockerDelivery.selected.id === parsedMessage?.data) {
            navigate('/pickup/picked');
          }
          
          break;

        case 'get_local_ip':

          fetchLocalIP();
          break;

        default:
          console.log(connectionStatus)
      }
    }
  }

  return (
    showIconStatus &&
    <div
      className={cn(
        'rounded-full h-[30px] w-[30px] animate-pulse',
        {
          'bg-red-500': !socket.active,
          'bg-green-500': socket.active,
        }
      )}
    />
  )

};