import React from 'react'
import { tv } from 'tailwind-variants';
import cn from 'classnames'

const Button = ({ children, color, size, isDisabled = false, onClick }) => {

  return (
    <button
      onClick={!isDisabled ? onClick : null}
      className={cn(
        button({ size: size, color: isDisabled ? 'disabled' : color }),
        {
          'active:translate-y-[8px] duration-75 transition': !isDisabled
        }
      )}
    >
      {children}
    </button>
  )
}

export default Button;

const button = tv({
  base: "w-full flex justify-center items-center rounded-lg font-extralight focus:outline-none",
  variants: {
    color: {
      primary: 'bg-primary active:bg-dark/50 text-white border-secondary',
      secondary: 'bg-secondary active:bg-dark/50 text-white border-secondary',
      gold: 'bg-gold active:bg-dark/50 text-white border-secondary',
      light: 'bg-white active:bg-dark/50 active:text-white text-dark border-gold',
      dark: 'bg-secondary active:bg-secondary/50 text-white border-gold',
      error: 'bg-red-500 active:bg-dark/50 active:text-white border-white/40 text-white',
      success: 'bg-green-500 active:bg-dark/50 active:text-white border-white/40 text-white',
      disabled: 'bg-gray-300/30 border-white/20 text-white/50',
    },
    size: {
      'sm': 'text-base py-2 border-b-4',
      'md': 'text-base py-2 border-b-4',
      'lg': 'text-lg py-3 border-b-4',
      'xl': 'text-xl py-3 border-b-4',
      '2xl': 'text-2xl py-4 border-b-4',
      '3xl': 'text-4xl py-5 border-b-4',
      '4xl': 'text-4xl py-5 border-b-4',
      '5xl': 'text-5xl py-5 border-b-4',
      '6xl': 'text-6xl py-6 border-b-4',
      '7xl': 'text-7xl py-6 border-b-4',
    }
  },
  defaultVariants: {
    size: "4xl",
    color: "dark",
  }
});