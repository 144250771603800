import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateLoading } from '../app';
import { appSettings } from 'config/app';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import api from 'helpers/api'

// Create an async thunk for get lockers available sizes
export const fetchSizes = createAsyncThunk('deposit/fetchSizes', async (params, { dispatch }) => {

  try {
    dispatch(updateLoading(true));

    const response = await api.get(`api/screen/sizes?location=${params}`);

    if (!response.data.ResultCode) {

      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
})

export const fetchLocker = createAsyncThunk('deposit/fetchLocker', async (params, { dispatch }) => {

  const { location, size } = params;

  try {
    dispatch(updateLoading(true));

    const response = await api.get(`api/screen/locker?location=${location}&size=${size}`);

    if (!response.data.ResultCode) {

      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
})

export const depositOrder = createAsyncThunk('deposit/depositOrder', async (params, { dispatch }) => {

  try {
    dispatch(updateLoading(true));

    const response = await api.post('api/screen/deposit', params);

    if (!response.data.ResultCode) {

      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return true;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
})

// Create an async thunk for get available companies
export const fetchCompanies = createAsyncThunk('deposit/fetchCompanies', async (params, { dispatch }) => {

  try {
    dispatch(updateLoading(true));

    const response = await api.get('api/screen/companies?type=' + appSettings.type);

    if (!response.data.ResultCode) {

      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
})

// Create an async thunk for get buildings
export const fetchBuildings = createAsyncThunk('deposit/fetchBuildings', async (params, { dispatch }) => {

  try {
    dispatch(updateLoading(true));

    const response = await api.get('api/building/list');

    if (!response.data.ResultCode) {

      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
})

// Create an async thunk for update locker size required
export const updateSize = createAsyncThunk('deposit/updateSize', async (params) => {
  return params;
})

// Create an async thunk for update company
export const updateCompany = createAsyncThunk('deposit/updateCompany', async (params) => {
  return params;
})

// Create an async thunk for update building
export const updateBuilding = createAsyncThunk('deposit/updateBuilding', async (params) => {
  return params;
})

// Create an async thunk for update fraction
export const updateFraction = createAsyncThunk('deposit/updateFraction', async (params) => {
  return params;
})

// Create an async thunk for update user
export const updateUser= createAsyncThunk('deposit/updateUser', async (params) => {
  return params;
})


// Create an async thunk for update locker
export const updateLocker = createAsyncThunk('deposit/updateLocker', async (params) => {
  return params;
})

// Create an async thunk for reset state
export const resetDeposit = createAsyncThunk('deposit/resetDeposit', async (params, { dispatch }) => {
  return true;
});

export const depositSlice = createSlice({
  name: 'deposit',
  initialState: {
    size: {
      list: false,
      selected: false
    },
    company: {
      list: false,
      selected: false
    },
    building: {
      list: false,
      selected: false
    },
    fraction: {
      list: false,
      selected: false
    },
    user: {
      list: false,
      selected: false
    },
    locker: {
      selected: false,
      opened: false
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSizes.fulfilled, (state, action) => {
      state.size = {
        ...state.size,
        list: action.payload,
      };
    });
    builder.addCase(updateSize.fulfilled, (state, action) => {
      state.size = {
        ...state.size,
        selected: action.payload
      };
    });
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.company = {
        ...state.company,
        list: action.payload,
      };
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.company = {
        ...state.company,
        selected: action.payload
      };
    });
    builder.addCase(fetchBuildings.fulfilled, (state, action) => {
      state.building = {
        ...state.building,
        list: action.payload,
      };
    });
    builder.addCase(updateBuilding.fulfilled, (state, action) => {
      state.building = {
        ...state.building,
        selected: action.payload
      };
    });
    builder.addCase(updateFraction.fulfilled, (state, action) => {
      state.fraction = {
        ...state.fraction,
        selected: action.payload
      };
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = {
        ...state.fraction,
        selected: action.payload
      };
    });
    builder.addCase(fetchLocker.fulfilled, (state, action) => {
      state.locker = {
        ...state.locker,
        selected: action.payload
      };
    });
    builder.addCase(updateLocker.fulfilled, (state, action) => {
      state.locker = {
        ...state.locker,
        selected: action.payload.selected
      };
    });
    builder.addCase(depositOrder.fulfilled, (state, action) => {
      state.locker = {
        ...state.locker,
        opened: action.payload
      };
    });
    builder.addCase(resetDeposit.fulfilled, (state, action) => {
      state.size = {
        ...state.size,
        selected: false
      };
      state.company = {
        ...state.company,
        selected: false
      };
      state.building = {
        ...state.building,
        selected: false
      };
      state.fraction = {
        ...state.fraction,
        selected: false
      };
      state.user = {
        ...state.user,
        selected: false
      };
      state.locker = {
        opened: false,
        selected: false
      };
    });
  }
})

export default depositSlice.reducer
