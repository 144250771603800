import React from 'react';
import TopBar from 'components/navigation/TopBar';
import Footer from 'components/navigation/Footer';
import SubMenu from 'components/navigation/SubMenu';
import Loading from 'components/modals/Loading';
import { appSettings } from 'config/app';
import { Toaster } from 'react-hot-toast';

const VerticalOne = ({ children }) => {

  return (
    <div className="flex flex-col items-center w-screen h-screen bg-primary text-5xl font-extralight">
      <Loading />
      <Toaster
        position="top-center"
        containerClassName="text-2xl"
        toastOptions={appSettings.toast}
      />
      <div className="w-full h-[15%] pt-10">
        <TopBar />
      </div>
      <div className="flex h-[75%] w-[55%] [&>*]:h-full [&>*]:w-full">
        {children}
      </div>
      <div className="h-[5%] justify-center items-end w-[55%]">
        <SubMenu />
      </div>
      <div className="w-full h-[5%] pb-10">
        <Footer />
      </div>
    </div>
  )
}

export default VerticalOne;