import React from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading'

const Loading = () => {
  const { loading } = useSelector((state) => state.app)

  return (
    loading &&
    <div className="flex justify-center items-center bg-primary/90 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto h-[100%] max-h-full">
      <ReactLoading
        type="spinningBubbles"
        className="!fill-white"
        width="250px"
        height="250px"
        delay={50}
      />
    </div>
  )
}

export default Loading;