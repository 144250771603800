import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const updateLanguage = createAsyncThunk('appConfig/updateLanguage', async (params) => {
  return params;
})

export const updateLoading = createAsyncThunk('appConfig/updateLoading', async (params) => {
  return params;
})

export const updateSubmitting = createAsyncThunk('appConfig/updateSubmitting', async (params) => {
  return params;
})

export const updateToast = createAsyncThunk('appConfig/updateToast', async (params) => {
  return params;
})

export const updateSocketStatus = createAsyncThunk('appConfig/updateSocketStatus', async (params) => {
  return params;
})

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    language: "pt",
    loading: false,
    isSubmitting: false,
    socket: {
      active: false,
    },
    toast: {
      show: false,
      success: false,
      message: '',
      icon: false,
      timer: 4,
    },
  },

  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateLanguage.fulfilled, (state, action) => {
      state.language = action.payload
    })
    builder.addCase(updateLoading.fulfilled, (state, action) => {
      state.loading = action.payload
    })
    builder.addCase(updateSubmitting.fulfilled, (state, action) => {
      state.isSubmitting = action.payload
    })
    builder.addCase(updateToast.fulfilled, (state, action) => {
      state.toast = {
        show: action.payload.show,
        success: action.payload.success,
        message: action.payload.message,
        icon: action.payload.icon,
        time: action.payload.timer
      }
    })
    builder.addCase(updateSocketStatus.fulfilled, (state, action) => {
      state.socket = {
        active: action.payload
      }
    })
  }
})

export default appSlice.reducer
