import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WebSocket } from 'helpers/socket';
import { appSettings } from 'config/app';

const TopBar = () => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-3 px-10">
      <div className="col-span-1">
        <WebSocket showIconStatus />
      </div>
      <div className="col-span-1 flex justify-center w-full">
        <img
          className="w-[20vw]"
          src={appSettings.logo.url}
          alt={appSettings.logo.alt}
          onClick={() => navigate('/home')}
        />
      </div>
    </div>
  )
}

export default TopBar;