import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { submenu } from 'config/submenu';
import { appSettings } from 'config/app';
import Button from 'components/common/Button';
import { t } from 'i18next';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const SubMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [colorPrimary, setColorPrimary] = useState('rgb(0, 0, 0)');
  const [colorSecondary, setColorSecondary] = useState('rgb(0, 0, 0)');
  const [timerKey, setTimerKey] = useState(0);
  const timerRef = useRef(null);

  const handleNavigation = () => {
    navigate(submenu.route[pathname].goTo);
  }

  useEffect(() => {
    // Use JavaScript to get the computed value of the CSS variable
    const rootStyles = getComputedStyle(document.documentElement);
    const color1 = rootStyles.getPropertyValue('--color-gold').trim();
    const color2 = rootStyles.getPropertyValue('--color-secondary').trim();
    setColorPrimary(`rgb(${color1})`);
    setColorSecondary(`rgb(${color2})`);
  }, []);

  const handleDocumentClick = () => {
    // Reset the timer by changing the timer key
    setTimerKey(prevKey => prevKey + 1);
  }

  useEffect(() => {
    // Add a click event listener to the document
    document.addEventListener('click', handleDocumentClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    submenu.route[pathname] && submenu.route[pathname].show && (
      <div className="w-full">
        <Button color={submenu.route[pathname].color} onClick={handleNavigation}>
          <div className="w-full flex justify-center items-center space-x-7">
            <span>
              {t(submenu.route[pathname].label)}
            </span>
            {submenu.route[pathname].timer.show && (
              <div ref={timerRef}>
                <CountdownCircleTimer
                  key={timerKey}
                  isPlaying
                  duration={submenu.route[pathname].timer.duration}
                  colors={colorPrimary}
                  trailColor={colorSecondary}
                  size={appSettings.countdown.size}
                  strokeWidth={appSettings.countdown.strokeWidth}
                  onComplete={handleNavigation}
                />
              </div>
            )}
          </div>
        </Button>
      </div>
    )
  );
}

export default SubMenu;
