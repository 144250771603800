import React from 'react';
import { ReactComponent as PoweredByLokk } from 'assets/logos/PoweredByLokk.svg'

const Footer = () => {
  return (
    <div className="h-full flex justify-center items-end">
      <PoweredByLokk width="190" className="fill-white" />
    </div>
  )
}

export default Footer;