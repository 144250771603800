import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateLoading } from '../app';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import api from 'helpers/api'

// Create an async thunk for verifying a PIN
export const verifyPin = createAsyncThunk('delivery/verifyPin', async (params, { dispatch }) => {

  const { pin, location, navigate } = params;

  try {
    dispatch(updateLoading(true));

    const response = await api.get(`api/delivery/verifyPickUpPackageWithPin?pin=${pin}&location=${location}`);

    if (!response.data.ResultCode) {
      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    navigate("/locker/assigned", { state: { pin: pin } });
    return response?.data?.Data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
});

// Create an async thunk for open assigned locker with PIN
export const openWithPin = createAsyncThunk('delivery/openWithPin', async (params, { dispatch }) => {

  const { pin, location } = params;

  try {
    //dispatch(updateLoading(true));

    const response = await api.get(`api/delivery/pickUpPackageWithPin?pin=${pin}&location=${location}`);

    if (!response.data.ResultCode) {

      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
});

// Create an async thunk for reset state
export const resetDelivery = createAsyncThunk('delivery/resetDelivery', async (params, { dispatch }) => {
  return true;
});

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState: {
    order: {
      selected: false,
      pin: false
    },
    locker: {
      selected: false,
      opened: false
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(verifyPin.fulfilled, (state, action) => {
      state.locker = {
        opened: false,
        selected: action.payload.assignedLocker,
      };
      state.order = {
        selected: action.payload,
        pin: action.meta.arg.pin
      }
    });
    builder.addCase(openWithPin.fulfilled, (state, action) => {
      state.locker.opened = true
    });
    builder.addCase(resetDelivery.fulfilled, (state, action) => {
      state.locker = {
        opened: false,
        selected: false,
      };
      state.order = {
        selected: false,
        pin: false
      }
    });
  }
})

export default deliverySlice.reducer
