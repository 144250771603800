import React from 'react';
//Settings
import { appSettings } from 'config/app';
//Layouts
import VerticalOne from './VerticalOne';
import VerticalTwo from './VerticalTwo';

const Layout = ({ children }) => {

  const getLayout = () => {
    switch (appSettings.layout) {
      case 'vertical-one':
        return <VerticalOne children={children} />
      case 'vertical-two':
        return <VerticalTwo children={children} />
      default:
        return <VerticalOne children={children} />
    }


  }

  return getLayout()

}

export default Layout