import { appSettings } from "./app"

export const submenu = {
  route: {
    '/': {
      goTo: '/deposit/size',
      label: 'general.deposit',
      color: 'dark',
      show: appSettings.type === 'delivery' ? true :  false,
      timer: {
        show: false,
        duration: 0
      }
    },
    '/home': {
      goTo: '/deposit/size',
      label: 'general.deposit',
      show: appSettings.type === 'delivery' ? true :  false,
      color: 'dark',
      timer: {
        show: false,
        duration: 0
      }
    },
    '/locker/assigned': {
      goTo: '/home',
      label: 'general.back',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
    '/locker/opened': {
      goTo: '/home',
      label: 'general.back',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
    '/pickup/picked': {
      goTo: '/home',
      label: 'general.exit',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
    '/deposit/size': {
      goTo: '/home',
      label: 'general.exit',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
    '/deposit/company': {
      goTo: '/home',
      label: 'general.exit',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
    '/deposit/building': {
      goTo: '/home',
      label: 'general.exit',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
    '/deposit/fraction': {
      goTo: '/home',
      label: 'general.exit',
      show: true,
      color: 'dark',
      timer: {
        show: 10,
        duration: 10
      }
    },
    '/deposit/user': {
      goTo: '/home',
      label: 'general.exit',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
    '/deposit/confirm': {
      goTo: '/home',
      label: 'general.exit',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
    '/deposit/locker': {
      goTo: '/home',
      label: 'deposit.cancel-deposit',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
    '/deposit/stored': {
      goTo: '/home',
      label: 'general.exit',
      show: true,
      color: 'dark',
      timer: {
        show: true,
        duration: 10
      }
    },
  },
}